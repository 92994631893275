import './App.scss';

function App() {
  return (
    <div className="maincontainer">
      <h1>Hello world!</h1>
      <div className="spacer-large"></div>
      <p>You are probably wondering whats happening here...</p>
      <p>So do i...</p>
      <a href="mailto:hello@guild.services"><code>:o</code></a>

    </div>
  );
}

export default App;
